export default function InstagramLogo() {
  return (
    <img
      src="https://static.xx.fbcdn.net/rsrc.php/v3/yx/r/tBxa1IFcTQH.png"
      alt="Instagram"
      width={24}
      height={24}
    />
  );
}
